@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --clr-bg-dark: #1f2937 !important; /* gray-800 */
  --clr-bg-light: #fafafa !important; /* zinc-50 */

  --clr-text-dark: #1f2937 !important; /* gray-200 */
  --clr-text-light: #fafafa !important; /* zinc-50 */
}

 
.dark {
  background-color: var(--clr-bg-dark) !important;
  color: var(--clr-text-dark) !important;
}

.light {
  background-color: var(--clr-bg-light) !important;
  color: var(--clr-text-light) !important;
}
.custom-circular-progressbar {
  width: 50%;
  max-width: 200px;
  height: auto;
  margin: 0 auto;
}

/* Styles for medium-sized screens (tablets) */
@media (max-width: 768px) {
  .custom-circular-progressbar {
    width: 70%;
    max-width: none;
  }
}

/* Styles for smaller screens (mobile) */
@media (max-width: 480px) {
  .custom-circular-progressbar {
    width: 80%;
    max-width: none;
  }
}

.font-1 {
    font-family: 'Montserrat', sans-serif !important;
}


.font-2 {
    font-family: 'Poppins', sans-serif !important;
}
.social svg {
    fill: #F8F6F0 !important;
    opacity:0.5
  }

.social:hover svg {
    fill: #F8F6F0 !important;
    opacity:1
  }

.prof{
  text-decoration: none !important;
}



.clip-polygon {
  clip-path: polygon(13.2% 0.4%, 100.2% 0.4%, 100.2% 75%, 86.7% 100.4%, 0% 100.4%, 0% 25.7%);
}

.shadow-sides {
  box-shadow: 16px 0 24px -12px rgba(0, 0, 0, 0.2), -16px 0 24px -12px rgba(0, 0, 0, 0.2);
}

.animated-button {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  margin-right: 1rem;
  transition: all 0.3s ease-in-out;
}

.animated-button.large {
  font-size: 1.5rem;
  padding: 1rem 2rem;
}

.animated-button.small {
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
}

.button-container {
  display: flex;
  margin: 2rem;
}

.toggle-button {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  cursor: pointer;
}
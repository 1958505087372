* {
  box-sizing: border-box;
}

body {
  background: #b3bfce;
}

.style {
  /* color: #FFFFFF; */
  text-align: center;
  font-family: sans-serif;
  text-transform: uppercase;
  font-size: 40px;
  position: relative;
}

.style:after {
  position: absolute;
  content: "";
  -webkit-animation: Dots 2s cubic-bezier(0, .39, 1, .68) infinite;
  animation: Dots 2s cubic-bezier(0, .39, 1, .68) infinite;
}

.style2:after {
    position: absolute;
    content: "";
    -webkit-animation: Dots 2s cubic-bezier(0, .39, 1, .68) infinite;
    animation: Dots 2s cubic-bezier(0, .39, 1, .68) infinite;
  }
.loader {
  margin: ;
}

.book {
  border: 4px solid #FFFFFF;
  width: 15vw;
  height: 30vh;
  position: relative;
  perspective: 100vh;
}

.page {
  display: block;
  width: 15vw;
  height: 30vh;
  border: 4px solid #FFFFFF;
  border-left: 1px solid #002D72;
 
  position: absolute;
  margin-top: -4px;
  overflow: hidden;
  background: #002D72;
  transform-style: preserve-3d;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.book .page:nth-child(1) {
  
  -webkit-animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.6s infinite;
  animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.6s infinite;
}

.book .page:nth-child(2) {
  
  -webkit-animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.45s infinite;
  animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.45s infinite;
}

.book .page:nth-child(3) {
 
  -webkit-animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.2s infinite;
  animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.2s infinite;
}


/* Page turn */

@-webkit-keyframes pageTurn {
  0% {
    -webkit-transform: rotateY( 0deg);
    transform: rotateY( 0deg);
  }
  20% {
    background: #hhhhhh;
  }
  40% {
    background: #b3bfce;
    -webkit-transform: rotateY( -180deg);
    transform: rotateY( -180deg);
  }
  100% {
    background: #b3bfce;
    -webkit-transform: rotateY( -180deg);
    transform: rotateY( -180deg);
  }
}

@keyframes pageTurn {
  0% {
    transform: rotateY( 0deg);
  }
  20% {
    background: #hhhhhh;
  }
  40% {
    background: #b3bfce;
    transform: rotateY( -180deg);
  }
  100% {
    background: #b3bfce;
    transform: rotateY( -180deg);
  }
}


/* Dots */

@-webkit-keyframes Dots {
  0% {
    content: "";
  }
  33% {
    content: ".";
  }
  66% {
    content: "..";
  }
  100% {
    content: "...";
  }
}

@keyframes Dots {
  0% {
    content: "";
  }
  33% {
    content: ".";
  }
  66% {
    content: "..";
  }
  100% {
    content: "...";
  }
}













@media (max-width: 600px) {
  .book,
  .page {
    width: 30vw;
    height: 25vh;
    margin-top: -4px;
  }
}